<template>
  <div class="base-container">
    <vxe-toolbar perfect size="mini">
      <template v-slot:buttons>
        <div class="cf table-header-padding">
          <div class="fl">
            <el-button type="primary" @click="getDetail()" size="mini" class="el-icon-plus" style="margin-right: 10px">
              新增打印机
            </el-button>
            <el-input v-model="search.name" clearable placeholder="输入名称" size="mini"
              style="width: 120px; margin-right: 10px" @change="searchInit()">
            </el-input>
            <el-input v-model="search.code" clearable placeholder="输入编号" size="mini" style="width: 120px; margin-right: 10px"
              @change="searchInit()">
            </el-input>
            <el-select v-model="search.type" clearable filterable placeholder="选择类型" size="mini"
                       style="width: 180px; margin-right: 10px" @change="searchInit()">
              <el-option v-for="(item, index) in typeList" :key="index" :label="item.value" :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="fr">
            <el-tooltip class="item" effect="dark" content="查询" placement="top">
              <el-button size="mini" @click="searchInit()" circle class="el-icon-search">
              </el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="清空" placement="top">
              <el-button size="mini" @click="search={}; searchInit()" circle class="el-icon-delete">
              </el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="导出" placement="top">
              <el-button @click="exportDataEvent()" size="mini" class="el-icon-download" circle></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="打印" placement="top">
              <el-button @click="printDataEvent()" size="mini" circle class="el-icon-printer">
              </el-button>
            </el-tooltip>
          </div>
        </div>
      </template>
    </vxe-toolbar>
    <vxe-table :align="allAlign" :data="data" :height="tableHeight" border :export-config="{}" :print-config="{}"
      highlight-hover-row auto-resize size="small" ref="printer" resizable row-id="id" v-loading="loading"
      element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading" show-header-overflow show-overflow
      @checkbox-change="changeBoxChange" @checkbox-all="changeBoxChange">
      <template #empty>
        <el-empty></el-empty>
      </template>
      <vxe-table-column type="checkbox" width="45" fixed="left" align="center"></vxe-table-column>
      <vxe-table-column field="name" min-width="120" title="名称"></vxe-table-column>
      <vxe-table-column field="code" min-width="150" title="编号"></vxe-table-column>
      <vxe-table-column field="type" min-width="150" title="类型"></vxe-table-column>
      <vxe-table-column field="address" min-width="150" title="地址"></vxe-table-column>
      <vxe-table-column fixed="right" align="center" width="240" title="操作">
        <template slot-scope="scope">
          <el-button type="text" size="mini" icon="el-icon-edit" @click="getUpdate(scope.row)">编辑</el-button>
          <el-button v-if="['', null, undefined].includes(scope.row.address)" type="text" size="mini" icon="el-icon-edit"
                     @click="getAddress(scope.row)">绑定地址</el-button>
          <el-button v-if="!['', null, undefined].includes(scope.row.address)" type="text" size="mini" icon="el-icon-edit"
                     @click="getCloseAddress(scope.row)">解除绑定</el-button>
          <el-button type="text" size="mini" icon="el-icon-delete" @click="getDelete(scope.row)">删除</el-button>
        </template>
      </vxe-table-column>
    </vxe-table>
    <vxe-pager perfect size="small" align="left" :loading="loading" :current-page="page.currentPage"
      :page-size="page.pageSize" :total="page.totalResult" class="ext-pager" selectionChange
      :layouts="['PrevPage', 'JumpNumber', 'NextPage', 'FullJump', 'Sizes', 'Total']"
      :page-sizes="[10, 20, 30, 40, 50, 100, 200, 300, 400, 500]" @page-change="handlePageChange">
      <template v-slot:left>
        <div class="fl footer-check">
          <p>
            已选
            <b style="font-size: 10px">{{ checkLength }}</b> 条
          </p>
        </div>
      </template>
    </vxe-pager>
    <detail ref="detail" @init="init" />
    <update-address ref="address" @init="init" />
  </div>
</template>

<script>
import detail from './components/detail'
import updateAddress from './components/address'

export default {
  name: 'printer',
  data() {
    return {
      data: [],
      tableHeight: window.innerHeight - 190 + 'px',
      allAlign: 'center',
      loading: false,
      checkBox: [],
      checkLength: 0,
      page: {
        pageSize: 20,
        currentPage: 1,
        totalResult: 0,
      },
      search: {},
      isNull: ['', null, undefined],
      typeList: [
        {value: 'MCP58'},
        {value: 'MCP350'},
        {value: 'MCP330'},
        {value: 'MCP360'},
        {value: 'MCP610'},
        {value: 'MCP230'},
        {value: 'CFP535'},
        {value: 'CFP820'},
        {value: 'CLP180'},
        {value: 'CLQ200FW'},
      ]
    }
  },
  created() {
    this.init()
  },
  components: { detail, updateAddress },
  mounted() {
    //监听元素变化
    window.onresize = () => {
      if (this.$refs.printer) {
        this.tableHeight = window.innerHeight - this.$refs.printer.$el.offsetTop - 62 + 'px'
      }
    }
  },
  methods: {
    init() {
      this.loading = true
      const params = {
        params: {
          pageNum: this.page.currentPage,
          pageSize: this.page.pageSize,
        }
      }
      if (!this.isNull.includes(this.search.name)) params.params.name = this.search.name
      if (!this.isNull.includes(this.search.code)) params.params.code = this.search.code
      if (!this.isNull.includes(this.search.type)) params.params.type = this.search.type
      this.$axios.get('/order/jolimark/back/printer/page', params).then((res) => {
          if (res) {
            const data = res.data.data
            this.data = data.list
            this.page.totalResult = data.total
            this.checkBox = []
            this.checkLength = 0
          }
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    exportDataEvent() {
      this.$refs.printer.openExport()
    },
    printDataEvent() {
      this.$refs.printer.openPrint()
    },
    getDetail() {
      let data = {
        data: {},
        name: '新增打印机',
        typeList: this.typeList
      }
      this.$refs.detail.display(data)
    },
    getUpdate(row) {
      let data = {
        data: row,
        name: `编辑${row.name}`,
        typeList: this.typeList
      }
      this.$refs.detail.display(data)
    },
    getAddress(row) {
      let data = {
        data: {},
        name: `绑定地址`,
        printerId: row.id
      }
      this.$refs.address.display(data)
    },
    getCloseAddress(row) {
      this.$confirm(`确定解除绑定吗？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
          .then(() => {
            this.$axios.post(`/order/jolimark/back/printer/cancel/${row.id}`).then((res) => {
              if (res) {
                this.init()
                this.$message.success('解除成功')
              }
            })
          })
          .catch(() => {})
    },
    /** 选择条数 */
    changeBoxChange() {
      this.checkBox = this.$refs.printer.getCheckboxRecords()
      this.checkLength = this.$refs.printer.getCheckboxRecords().length
    },
    /** 条数更改 */
    handlePageChange({ currentPage, pageSize }) {
      const _this = this
      _this.page.currentPage = currentPage
      _this.page.pageSize = pageSize
      _this.init()
    },
    searchInit() {
      this.page.currentPage = 1
      this.init()
    },
    getDelete(row) {
      this.$confirm(`确定删除这条数据吗？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$axios.post(`/order/jolimark/back/printer/delete/${row.id}`).then((res) => {
            if (res) {
              this.init()
              this.$message.success('删除成功')
            }
          })
        })
        .catch(() => {})
    },
  },
}
</script>

<style scoped></style>
