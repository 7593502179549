<template>
  <div v-if="show">
    <vxe-modal v-model="show" :title="name" width="700" min-width="700" height="300" min-height="300" showFooter
      esc-closable mask-closable resize>
      <template v-slot>
        <el-form ref="form" size="small" :model="data" label-width="auto" label-position="right" :rules="rules">
          <el-form-item prop="name" label="名称">
            <el-input v-model="data.name" placeholder="输入名称" type="text"></el-input>
          </el-form-item>
          <el-form-item prop="code" label="编号">
            <el-input v-model="data.code" placeholder="输入编号" type="text"></el-input>
          </el-form-item>
          <el-form-item prop="type" label="类型">
            <el-select filterable default-first-option v-model="data.type" placeholder="选择类型"
                       style="width: 100%">
              <el-option :label="item.value" :value="item.value" v-for="(item, key) in typeList" :key="key">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </template>
      <template v-slot:footer>
        <div style="text-align: center">
          <vxe-button status="primary" style="width: 100%" @click="save" v-loading="loading">确定</vxe-button>
        </div>
      </template>
    </vxe-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      name: '',
      data: {},
      rules: {
        name: [{ required: true, trigger: 'blur', message: '输入公司名称' }],
        mobile: [{ required: true, trigger: 'blur', message: '输入手机号'}],
        type: [{ required: true, trigger: 'change', message: '选择类型' }],
      },
      loading: false,
      isDisable: true,
    }
  },
  methods: {
    /** 打开界面方法 */
    display(data) {
      this.show = true
      this.data = this.$utils.clone(data.data, true)
      this.typeList = data.typeList
      this.name = data.name
    },
    /** 保存方法 */
    save() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true
          const params = this.data
          let url = ''
          if (this.$utils.isUndefined(this.data.id)){
            url = '/order/jolimark/back/printer'
          } else {
            url = '/order/jolimark/back/printer/update'
          }
          this.$axios.post(url, params).then((res) => {
            if (res) {
              if (this.$utils.isUndefined(this.data.id)){
                this.$message.success('新增成功')
              }
              else {
                this.$message.success('编辑成功')
              }
              this.show = false
              this.$emit('init')
            }
            this.loading = false
          })
          .catch((err) => {
            console.log(err)
            this.loading = false
          })
        } else {
          return false
        }
      })
    },
  },
}
</script>