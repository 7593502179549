<template>
  <div v-if="show">
    <vxe-modal v-model="show" :title="name" width="400" min-width="400" height="210" min-height="210" showFooter
               esc-closable mask-closable resize>
      <template v-slot>
        <el-form ref="form" size="small" :model="data" label-width="150px" label-position="top" :rules="rules">
          <el-form-item prop="address" label="过磅地址">
            <el-input v-model.trim="data.address" placeholder="请输入过磅地址" type="text"></el-input>
          </el-form-item>
        </el-form>
      </template>
      <template v-slot:footer>
        <div style="text-align: center">
          <vxe-button status="primary" style="width: 100%" @click="save" v-loading="loading">确定</vxe-button>
        </div>
      </template>
    </vxe-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      name: '',
      data: {},
      rules: {
        address: [{ required: true, trigger: 'blur', message: '输入过磅地址' }],
      },
      loading: false,
      printerId: null
    }
  },
  methods: {
    /** 打开界面方法 */
    display(data) {
      this.show = true
      this.data = this.$utils.clone(data.data, true)
      this.name = data.name
      this.printerId = data.printerId
    },
    /** 保存方法 */
    save() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true
          const params = {
            address: this.data.address,
            printerId: this.printerId
          }
          this.$axios.post('/order/jolimark/back/printer/updateAddress', params).then((res) => {
            if (res) {
              this.$message.success('绑定成功')
              this.show = false
              this.$emit('init')
            }
            this.loading = false
          })
              .catch((err) => {
                console.log(err)
                this.loading = false
              })
        } else {
          return false
        }
      })
    },
  },
}
</script>